<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Rental Requests</h1>
						</div>
						<router-link to="/charter/create" class="btn btn-primary">Create Rental</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div>
				<div class="row mt-4">
					<div class="col-12">
						<div class="card">
							<div class="card-header justify-content-end">
								<div class="col-auto">
									<v-datepicker
										v-model="range"
										placholder="Filter by date"
										range
										></v-datepicker>
								</div>
							</div>
							<v-server-table
								ref="chartersTable"
								selectable
								:columns="columns"
								:options="options"
								@row-click="showCharter"
								class='cursor-pointer'
							>
								<p slot="S/N" slot-scope="props">
									{{ props?.index }}
								</p>
								<div slot="Date created" slot-scope="props">
									<span>{{ props.row.created_at | date('yyyy-MM-dd hh:mm a') }}</span>
								</div>
								<div slot="Pickup" slot-scope="props">
									<span>
										{{ props.row.pickup_date | date('yyyy-MM-dd') }}
										{{ props.row.pickup_time }}
									</span>
								</div>
								<div slot="From" slot-scope="props">
									<span>{{ props.row.pickup_address }}</span>
								</div>
								<div slot="To" slot-scope="props">
									<span>{{ props.row.dropoff_address }}</span>
								</div>
								<div slot="Vehicle Type" slot-scope="props">
									<span>{{ props.row.vehicles }}</span>
								</div>
								<div slot="Customer Name" slot-scope="props">
									<span>{{ `${props.row.fname} ${props.row.lname}` }}</span>
								</div>
								<div slot="Customer Phone" slot-scope="props">
									<span>{{ props.row.phone }}</span>
								</div>
								<div slot="Status" slot-scope="props">
									<span>{{ props.value }}</span>
								</div>
								<div slot="Trip Type" slot-scope="props">
									<span>{{
										!props.row.return_date ? 'One Way' : 'Return'
									}}</span>
								</div>
							</v-server-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
  components: {},
  data () {
    return {
      columns: [
		'S/N',
        'Pickup',
        'From',
        'To',
        'Vehicle Type',
        'Customer Name',
        'Customer Phone',
        'Status',
        'Trip Type',
        'Date created'
      ],
      options: {
        filterable: true,
        requestFunction: (data) => {
          return this.fetchCharters(data)
        }
      },
      range: []
    }
  },
  watch: {
    range (value) {
      if (value) {
        this.$refs.chartersTable.refresh()
      }
    }
  },
  computed: {
    dateFilterValid () {
      return this.range.length && this.range.filter((date) => !!date).length > 0
    },
    dateRange () {
      return this.range.map((date) => moment(date).format('YYYY-MM-DD'))
    }
  },
  methods: {
    async fetchCharters (params) {
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1
      const query = params ? params.query : ''

      let url = `/v1/charters?limit=${limit}&page=${page}`
      if (query) {
        url += `&search=${query}`
      }
      if (this.dateFilterValid) {
        url += `&from=${this.dateRange[0]}&to=${this.dateRange[1]}`
      }
      const result = await this.axios({
        url,
        method: 'GET',
        data: {
          search_term: query
        }
      })
      const data = result.data.data || []
      const newResult = {
        data: data,
        count: result?.data?.metadata?.total
      }

      return {
        data: newResult
      }
    },
    showCharter (charter) {
      this.$router.push({
        name: 'ShowCharter',
        params: { charterId: charter.row.id }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
